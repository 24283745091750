import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useCallback, useState, Suspense } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import AOS from 'aos';
import 'aos/dist/aos.css';

//css
import Style from './css/Style.css'
import MediaQuery from './css/MediaQuery.css'

//pages
import Gotoup from './components/Gotoup';
import Footer from './components/Footer';


function App() {

  const [loading, setLoaging] = useState(false);

  useEffect(() => {
    setLoaging(true);
    setTimeout(() => {
      setLoaging(false);
    })
  }, 13000)

  const Home = React.lazy(() => import('./components/Home'));
  const AboutUs = React.lazy(() => import('./components/AboutUs'));
  const Contact = React.lazy(() => import('./components/Contact'));
  // const ContactUs = React.lazy( () => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () =>
  //       Math.floor(Math.random() * 10) >= 4
  //     ? import('./components/ContactUs') 
  //     : Promise.reject(new Error())  
  //     );
  // })
  const Project = React.lazy(() => import('./components/Project'));
  const Services = React.lazy(() => import('./components/Services'));


  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);


  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className=''>
        <Suspense fallback={
          <div className='spinner'>
            <p>l</p>
            <p>o</p>
            <p>a</p>
            <p>d</p>
            <p>i</p>
            <p>n</p>
            <p>g</p>
          </div>

        }>
          <div>
            <Gotoup />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/project" element={<Project />} />
              <Route path="/contact" element={<Contact />} />
              {/* <Route path="/thank" element={<Thank />} /> */}
              {/* <Route path="*" element={<Error />} /> */}
            </Routes>
            <Footer />
          </div>
        </Suspense>
      </div>
    </>
  );
}

export default App;
