import React from 'react'
import { Link } from 'react-router-dom';
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa6";
import { GrTwitter } from "react-icons/gr";

export default function Footer() {
    return (
        <>
            {/* <footer className='theme-black'>
                <div className="container">
                    <div className="row footer-menu-row">
                        <div className="col-lg-5 col-md-12">
                            <div className='footer-logo'>
                                <img src={require('../images/logo.png')} alt="" className='w-100' />
                            </div>
                            <div className='d-flex justify-content-end align-item-end'>
                                <div className='followus-icon-container d-flex my-md-3 me-md-5 my-4'>
                                    <div className='followus-single-icon-div'>
                                        <Link to='' ><RiInstagramFill /></Link>
                                    </div>
                                    <div className='followus-single-icon-div'>
                                        <Link to='' ><FaFacebookF /> </Link>
                                    </div>
                                    <div className='followus-single-icon-div'>
                                        <Link to='' ><GrTwitter /></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className='mb-4'>Main Menu</h5>
                            <div className='footer-link'>
                                <p><Link to='/' >Home</Link></p>
                                <p><Link to='/about' >About Us</Link></p>
                                <p><Link to='/services' >Services</Link></p>
                                <p><Link to='/services' >Project</Link></p>
                                <p><Link to='/services' >Contact Us</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6"></div>
                    </div>
                    <div className="row copyright-line">
                        <div className="col text-center pt-5">
                            <p>Copyright © 2023 Anveshi Group of Co.</p>
                        </div>
                    </div>
                </div>
            </footer> */}

            <footer>
                <div class="footer">
                    <div class="row">
                        <div className="col">
                            <Link target='_blank' to='https://www.instagram.com/anveshi_group/?igsh=ODA1NTc5OTg5Nw%3D%3D' ><RiInstagramFill /></Link>
                            <Link target='_blank' to='' ><FaFacebookF /> </Link>
                            <Link target='_blank' to='' ><GrTwitter /> </Link>
                        </div>
                    </div>

                    <div class="row">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/services">Sevices</Link></li>
                            <li><Link to="/project">Project</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>

                    <div class="row justify-center copyright-content">
                        <p className='text-center'>Anveshi Group of Co. Copyright © 2021 - All rights reserved</p>
                    </div>
                </div>
            </footer>
        </>
    )
}
